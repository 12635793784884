window.fbAsyncInit = function() {
FB.init({
    appId      : process.env.MIX_FACEBOOK_APP_ID,
    cookie     : true,
    xfbml      : true,
    version    : process.env.MIX_FACEBOOK_API_VERSION
});

FB.AppEvents.logPageView();

};

(function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));


/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    // window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
} catch (e) {
    console.log(`${e}. Required libraries failed to load.`)
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.defaults.withCredentials = true;
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');
/*
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true
});
*/
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '1d13a6f819729694e66c',
    cluster: 'mt1',
    forceTLS: true
});

/**
* AlpineJS
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();
*/

window.urlParam = window.$.urlParam = window.jQuery.urlParam = function (name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)')
                      .exec(window.location.search);

    return (results !== null) ? results[1] || 0 : false;
};
//Landkit required libs
//window.$.jarallax = window.JQuery.jarallax = window.jarallax = require('jarallax');
// import {
//     jarallax,
//     jarallaxElement,
//     jarallaxVideo
// } from 'jarallax';
// jarallaxVideo();
//jarallax();
//window.$.smartWizard = window.jQuery.smartWizard = require('smartwizard')
window.datepicker = window.$.datepicker = window.jQuery.datepicker = require('bootstrap-datepicker');
// require('@fancyapps/fancybox');
// window.AOS = require('aos');
// window.Choices = require('choices.js');
// window.CountUp = require('countup.js');
//window.Dropzone = require('dropzone');
// require('flickity');
// require('flickity-fade');
//window.hljk = require('highlightjs');
require('imagesloaded');
//require('isotope-layout');
// window.$.slimScroll = window.jQuery.slimScroll = require('jarallax');
//window.Quill = require('quill');
// window.SmoothScroll = require('smooth-scroll');
// window.Typed = require('typed.js');
// require('@fortawesome/fontawesome-free');
// window.$.slimScroll = window.jQuery.slimScroll = require('jquery-slimscroll');
//Landkit init scripts
// require('./landkit/choices');
// require('./landkit/polyfills');
// require('./landkit/aos');
// require('./landkit/choices');
// require('./landkit/countup');
// require('./landkit/dropdown');
// require('./landkit/dropzone');
// require('./landkit/fancybox');
// require('./landkit/highlight');
// require('./landkit/isotope');
// require('./landkit/map');
// require('./landkit/modal');
// require('./landkit/navbar');
// require('./landkit/popovers');
// require('./landkit/pricing');
// require('./landkit/quill');
// require('./landkit/smooth-scroll');
// require('./landkit/tooltips');
// require('./landkit/typed');

window.attachFileUpload = function(args){

    const defaults = {
        uploadButtonId: 'uploadButton',
        updateButtonHtml: true,
        imageIdFieldId: 'imageId',
        uploadFileFieldId: 'uploadFile',
        inputTypeFileHidden: false,
        hasProgressBar: true,
        progressBarId: 'progress-bar',
        hasPreview: true,
    };
    const options = { ...defaults, ...args};
    var uploadAjax = null;
    function fileInputChange(e){

        // Clean up -- remove any previously uploaded image
        var imageId = $("#" + options.imageIdFieldId).val();
        if (imageId && imageId != "") {
            var formData = new FormData();
            formData.append('imageId', imageId);
            $.ajax({
                url: "/image/remove",
                headers: { 'X-CSRF-TOKEN': csrf_token['csrfToken'] },
                method: 'POST',
                processData: false,
                contentType: false,
                data: formData
            }).done(function(data) {
                console.log('remove done');
            }).fail(function(jqXHR, textStatus) {
                console.log('remove fail');
            });
        }
        $("#" + options.imageIdFieldId).val("");

        // Upload
        var image = $("#" + options.uploadFileFieldId)[0].files[0];
        if(image.size > 5000000){
            $('#file_too_large_modal').modal('show');
            return;
        }
        var formData = new FormData();
        formData.append('image', image);

        $('#image_uploading_modal').modal('show');
        uploadAjax = $.ajax({
            xhr: function() {
                var xhr = new window.XMLHttpRequest();
                xhr.upload.addEventListener("progress", function (evt) {
                    if (evt.lengthComputable) {
                        var path = document.querySelector('#upload_meter path');
                        var length = path.getTotalLength();
                        var text = document.querySelector('#upload_meter .circ-text');
                        var percentComplete = evt.loaded / evt.total;
                        var i = parseInt(percentComplete * length);
                        percentComplete = parseInt(percentComplete * 100);
                        path.setAttribute('stroke-dasharray', i + "," + length);
                        text.innerHTML = percentComplete + "%"

                    }
                });
                return xhr;
            },
            url: "/image/upload",
            headers: { 'X-CSRF-TOKEN': csrf_token['csrfToken'] },
            method: 'POST',
            processData: false,
            contentType: false,
            data: formData
        }).done(function(data) {

            $('#image_uploading_modal').modal('hide');
            $("#" + options.imageIdFieldId).val(data.imageId);
            var message = $('#message');
            message.append('<img src="' + data.url + '" class="message-image">');
            $("#image_path").val(data.url);
            $('#message').trigger('focus');
            $('#upload_image').val('');
        }).fail(function(jqXHR, textStatus) {
            $('#image_uploading_modal').modal('hide');
            $("#" + options.imageIdFieldId).val("");
            $('#message').empty();
            $('#message').trigger('focus');
            $('#upload_image').val('');
            if(jqXHR.status == 413){
                $('#image_uploading_modal').modal('hide');
                $('#file_too_large_modal').modal('show');
            } else {
                $('#image_uploading_modal').modal('hide');
                $('#generic_error_modal').modal('show');
            }
        });
    }
    $("#" + options.uploadButtonId).on('click', function(e) {
        if(options.inputTypeFileHidden){
            $("#" + options.uploadFileFieldId).trigger('click');
        } else {
            fileInputChange(e);
        }
    });
    $("#" + options.uploadFileFieldId).on('change', function(e){
        fileInputChange(e)
    });
}

window.DMUtils = {
    dateDiffInDays: function(lessRecentDate, moreRecentDate) {
        let dateTime1 = new Date(lessRecentDate).getTime();
        let dateTime2 = new Date(moreRecentDate).getTime();
        return (dateTime2 - dateTime1) / (1000 * 3600 * 24);
    }
}
